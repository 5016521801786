import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux'
import authService from '../services/auth.service';
import AppNavbar from './components/appNavbar/appNavbar';
import NotFound from './pages/404/404';
import { io } from "socket.io-client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import notificationSound from '../../sound/notification.mp3';
import useSound from 'use-sound';
import { Store } from './store/store';

const DashboardComponent = React.lazy(() => import('./pages/dashboard/dashboard'));
const OrdersComponent = React.lazy(() => import('./pages/orders/orders'));
const InvoicesComponent = React.lazy(() => import('./pages/invoices/invoices'));
const OrderSummaryComponent = React.lazy(() => import('./pages/orderSummary/orderSummary'));
const InventoryComponent = React.lazy(() => import('./pages/inventory/inventory'));
const AccountSettingsComponent = React.lazy(() => import('./pages/settings/accountSettings'));
const HelpAndSupportComponent = React.lazy(() => import('./pages/Help/help'));
const OrderDetails = React.lazy(() => import('./pages/orderDetails/orderDetails'));
const InverterOrderDetails = React.lazy(() => import('./pages/orderDetails/inverterOrderDetails'));
const ServiceOrderDetails = React.lazy(() => import('./pages/orderDetails/serviceOrderDetails'));


const PanelComponent = (props) => {

    let { path } = useRouteMatch();

    const [socketConnected, setSocketConnected] = useState(false);
    const [firstInstance, setFirstInstance] = useState(true);

    const [sound] = useSound(notificationSound, {volume: 1});

    const playNotificationSound = () => {
        sound();
    }

    // web socket for notification
    useEffect(() => {
        
        const socket = io(`${process.env.REACT_APP_SOCKET_URL}?id=${authService.getFullUser().id}`);
        socket.on('newOrder', (data) => {
            switch (data.type) {
                case 'BATTERY':
                    toast(<ToastItem {...props} type={'Battery'} url={''}></ToastItem>);
                    playNotificationSound();        
                    break;
                case 'INVERTER':
                    toast(<ToastItem {...props} type={'Inverter'} url={'/inverter'}></ToastItem>);
                    playNotificationSound();       
                    break;
                case 'SERVICE':
                    toast(<ToastItem {...props} type={'Service'} url={'/services'}></ToastItem>);
                    playNotificationSound();             
                    break;
                default:
                    break;
            }
        })
        socket.on('connect', () => {setSocketConnected(true)})
        socket.on('disconnect', () => {setSocketConnected(false)})
        socket.on('reconnect', () => {setSocketConnected(true)})
        return () => {
            setSocketConnected(false)
            socket.disconnect();
        }
    }, [])

    // the toast component
    const ToastItem = (props) => {
        const history = useHistory();
        const location = useLocation();
        const path = `/orders${props.url}`;

        const onClick = () => {
            if(location.pathname == path) {
                history.push({pathname: '/empty'});
                setTimeout(() => {
                    history.replace({pathname: path});
                }, 1);
            } else {
                history.push(path);
            }
        }
        return (
            <div onClick={onClick}>
                <p className="text-sm font-semibold hover:text-blue-500">New {props.type} Order</p>
            </div>
        )
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <Provider store={Store}>
                <AppNavbar socketConnectionStatus={socketConnected}/>
                <ToastContainer/>
                <Suspense
                    fallback={<div className="flex items-center justify-center h-screen">
                        <div className="py-10 w-full flex items-center justify-center text-xl">
                            <div className="flex justify-center items-center">
                                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-500 mr-4"></div>
                            </div>
                        </div>
                    </div>}>
                    <Switch>
                        <Route path={path} exact
                            render = {() => {
                                if(firstInstance) {
                                    setFirstInstance(false);
                                    return (
                                        <Redirect to="/orders" />
                                    )
                                } else {
                                    return (
                                        <DashboardComponent {...props}/>
                                    )
                                }
                            }}></Route>
                        <Route path="/orders" render={() => { setFirstInstance(false); return (<OrdersComponent {...props}/>)}}></Route>
                        <Route path="/order-details" render={() => { setFirstInstance(false); return (<OrderDetails {...props}/>)}}></Route>
                        <Route path="/inverter-order-details" render={() => { setFirstInstance(false); return (<InverterOrderDetails {...props}/>)}}></Route>
                        <Route path="/service-order-details" render={() => { setFirstInstance(false); return (<ServiceOrderDetails {...props}/>)}}></Route>
                        <Route path="/invoices" render={() => { setFirstInstance(false); return (<InvoicesComponent {...props}/>)}}></Route>
                        <Route path="/order-summary" render={() => { setFirstInstance(false); return (<OrderSummaryComponent {...props}/>)}}></Route>
                        <Route path="/inventory" render={() => { setFirstInstance(false); return (<InventoryComponent {...props}/>)}}></Route>
                        <Route path="/account" render={() => { setFirstInstance(false); return (<AccountSettingsComponent {...props}/>)}}></Route>
                        <Route path="/help" render={() => { setFirstInstance(false); return (<HelpAndSupportComponent {...props}/>)}}></Route>
                        <Route path="" render={() => { setFirstInstance(false); return (<NotFound {...props}/>)}}></Route>
                    </Switch>
                </Suspense>
            </Provider>
        </div>
    )
}


export default PanelComponent
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Navbar from './components/navbar';
import LocationRegistrationComponent from './pages/location/location';
import NotFoundReg from './pages/notFound/notFoundReg';
import ProductsRegistrationPage from './pages/products/products';
import Register from './pages/register/register';
import ServicesRegisterPage from './pages/services/services';
import RegistrationSuccess from './pages/success/registrationSuccess';
import TechnicianRegisterPage from './pages/technician/technician';
import VerifyPhone from './pages/verify/verify';

const RegisterPanel = ({match}) => {
    return (
        <div className="bg-gray-100 min-h-screen">
            <Navbar />
            <Switch>
                <Route path={`${match.path}/`} exact component={Register}></Route>
                <Route path={`${match.path}/technician`} exact component={TechnicianRegisterPage}></Route>
                <Route path={`${match.path}/products`} exact component={ProductsRegistrationPage}></Route>
                <Route path={`${match.path}/services`} exact component={ServicesRegisterPage}></Route>
                <Route path={`${match.path}/location`} exact component={LocationRegistrationComponent}></Route>
                <Route path={`${match.path}/verify`} exact component={VerifyPhone}></Route>
                <Route path={`${match.path}/success`} exact component={RegistrationSuccess}></Route>
                <Route path="" component={NotFoundReg}></Route>
            </Switch>
        </div>
    )
}

export default RegisterPanel;
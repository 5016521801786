import React from 'react';
import './register.css';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'

// components
import Header from '../../components/header';
import registerService from '../../../../services/register.service';

const Register = (props) => {
      
    // regex
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const noWhiteSpaceRegEx = /^\S*$/;

    // validation schema
    const validationSchema = yup.object().shape({
        name: yup.string().trim().required('Enter your First Name').min(2, 'Name too short').max(120, 'Name too long'),
        businessName: yup.string().trim().required('Enter your business name').min(4, 'Name too short').max(120, 'Name too long'),
        gstNo: yup.string().trim().required('Enter your GST number').length(15, 'Enter a valid GST number.').matches(noWhiteSpaceRegEx, 'No white space allowed.'),
        email: yup.string().required('Email is required').email('Enter a valid Email'),
        phone: yup.string().trim().required('Phone number is required').length(10, 'Enter a valid 10 digit phone number').matches(phoneRegExp, 'Enter a valid phone number').matches(noWhiteSpaceRegEx, 'No white space allowed.'),
    })

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data) => {
        registerService.setDetails(data);
        props.history.push('/register/technician');
    }

    return (
        <div>
            <div className="bg-gray-100 py-2">
                <Header name='Register Your Account'/>
                <div className="px-4 max-w-6xl mx-auto pb-8">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bg-white p-3 sm:p-4 rounded-md shadow-sm mb-4">
                            <h4 className="text-base font-regular">Business Details</h4>
                            <p className="text-xs font-medium text-gray-400 mb-6">Enter your business details</p>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
                                <div className="flex flex-col">
                                    <label htmlFor="fullName" className="text-sm font-regular text-gray-800 mb-1">Business Owners Full Name</label>
                                    <input id="fullName" {...register("name")} maxLength="120" type="text" className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                                    <p className="text-sm text-red-600 py-1">{errors.name?.message}</p>
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor="email" className="text-sm font-regular text-gray-800 mb-1">Email Address</label>
                                    <input id="email" {...register("email")} maxLength="60" type="email" className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                                    <p className="text-sm text-red-600 py-1">{errors.email?.message}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
                                <div className="flex flex-col">
                                    <label htmlFor="phone" className="text-sm font-regular text-gray-800 mb-1">Phone Number</label>
                                    <input id="phone" maxLength="14" type="text" {...register("phone")} className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                                    <p className="text-sm text-red-600 py-1">{errors.phone?.message}</p>
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor="businessName" className="text-sm font-regular text-gray-800 mb-1">Name of Business</label>
                                    <input id="businessName" {...register("businessName")} maxLength="120" type="text" className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                                    <p className="text-sm text-red-600 py-1">{errors.businessName?.message}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
                                <div className="flex flex-col">
                                        <label htmlFor="gst" className="text-sm font-regular text-gray-800 mb-1">GST Number</label>
                                        <input id="gst" {...register("gstNo")} maxLength="15" type="text" className="uppercase text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                                        <p className="text-sm text-red-600 py-1">{errors.gstNo?.message}</p>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 flex justify-end">
                                <button type="submit" className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded">Save & Continue</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register;
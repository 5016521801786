import { createSlice } from "@reduxjs/toolkit";
import timeService from "../../Services/time.service";

export const ServiceOrderFilterSlice = createSlice({
  name: "ServiceOrderFilter",
  initialState: {
    statusFilter: 'ALL',
    statusFilterData: {value: 'ALL', label: 'All Orders'},
    timeFilter: timeService.getToday(),
    timeFilterData: {value: 'today', label: 'Today'}
  },
  reducers: {
    setStatusFilter: (state, action) => {
        state.statusFilter = action.payload.value;
        state.statusFilterData = action.payload.data;
    },
    setTimeFilter: (state, action) => {
        state.timeFilter = action.payload.value;
        state.timeFilterData = action.payload.data
    },
  }
});


export const selectServiceOrderFilters = (state) => state.serviceOrderFilter;

export const {setStatusFilter, setTimeFilter} = ServiceOrderFilterSlice.actions;

export default ServiceOrderFilterSlice.reducer;
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'

import Header from '../../components/header';
import registerService from '../../../../services/register.service';
import API from '../../../../services/axios';

const ServicesRegisterPage = (props) => {

    const [services, setServices] = useState([]);

    const validationSchema = yup.object().shape({
        services: yup.object({}).test(
            'servicesTest',
            'Please Select at lease one item',
            (obj) => {
                for (const [key, value] of Object.entries(obj)) {
                    if (value === true && key) return true
                }
                return false;
            }
        ),
    })

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        const data = registerService.getFormData();
        if (!data?.phone || !data) {
        props.history.push('/register')
        }
    })
    
    useEffect(() => {
        API.get('/service').then(res => {
            setServices(res.data.data);
        })
        .catch(err => {
            console.log(err);
        })
    }, [])


    const onSubmit = (data) => {
        registerService.setServices(data);
        props.history.push('/register/location');
    }

    return (
        <div>
            <div className="bg-gray-100 py-2">
                <Header name='Services you offer'/>
                <div className="px-4 max-w-6xl mx-auto pb-8">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bg-white p-3 sm:p-4 rounded-md shadow-sm mb-4">
                            <h4 className="text-base font-regular mb-6 font-bold">Please select the services you provide</h4>

                            <div className="flex flex-col">                            
                                <div className="flex flex-col gap-4">

                                    {services.map((i,index) => (
                                        <label key={index} className="flex items-center mb-1 cursor-pointer w-max">
                                            <input type="checkbox" {...register(`services[${i.id}]`)} className="opacity-0 absolute h-0 w-0 check-secondary" />
                                            <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 mr-2 focus-within:border-blue-500 flex items-center justify-center">
                                                <svg className="hidden" width="14" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.7069 0.293C13.8944 0.480528 13.9997 0.734836 13.9997 1C13.9997 1.26516 13.8944 1.51947 13.7069 1.707L5.70692 9.707C5.51939 9.89447 5.26508 9.99979 4.99992 9.99979C4.73475 9.99979 4.48045 9.89447 4.29292 9.707L0.292919 5.707C0.110761 5.5184 0.00996641 5.2658 0.0122448 5.0036C0.0145233 4.7414 0.119692 4.49059 0.3051 4.30518C0.490508 4.11977 0.741321 4.0146 1.00352 4.01233C1.26571 4.01005 1.51832 4.11084 1.70692 4.293L4.99992 7.586L12.2929 0.293C12.4804 0.105529 12.7348 0.000213623 12.9999 0.000213623C13.2651 0.000213623 13.5194 0.105529 13.7069 0.293Z" fill="#3F3F46"/>
                                                </svg>
                                            </div>
                                            <span className="ml-1 font-bold">{i.serviceType}</span>
                                        </label>
                                    ))}
                                </div>
                                <p className="text-sm text-red-600 py-2 mt-2 font-bold">{errors.services?.message}</p>
                            </div>
                        </div>

                        <div className="pt-4 flex justify-end">
                            <button type="submit" className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded">Save & Continue</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ServicesRegisterPage;

import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import PanelComponent from './application/app/panel';
import Login from './application/auth/login/login';
import RegisterPanel from './application/auth/register/registerPanel';
import UserApproveComponent from './application/auth/user-approve/userApprove';
import authService from './application/services/auth.service';
import ScrollToTop from './application/utilityComponents/ScrollToTop';

function App() {

  // Doesn't load when user is not logged in or token is not present in local storage
  const ProtectedRoute = ({component: Component, ...rest}) => {
    const user = authService.getCurrentUser();
    return (
      <Route {...rest} render={(props) => {
        return user ? (<Component {...rest} {...props}/>) : (<Redirect to="/login"/>);
      }}></Route>
    )
  }

  // Doesn't load when logged in or token exists in local storage
  const AuthRoute = ({component: Component, ...rest}) => {
    const user = authService.getCurrentUser();
    return (
        <Route {...rest} render={(props) => {
          return user ? (<Redirect to="/"/>) : (<Component {...rest} {...props}/>);
        }}></Route>
    )
  }

  return (
    <div className="font-inter">
      <Router>
        <ScrollToTop/>
        <Switch> 
          <AuthRoute path="/login" component={Login}></AuthRoute>
          <AuthRoute path="/register" component={RegisterPanel}></AuthRoute>
          <AuthRoute path="/admin/user-approve" component={UserApproveComponent}></AuthRoute>
          <ProtectedRoute path="/" component={PanelComponent}></ProtectedRoute>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

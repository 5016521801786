import { createSlice } from "@reduxjs/toolkit";
import timeService from "../../Services/time.service";

export const BatteryOrderFilterSlice = createSlice({
  name: "BatteryOrderFilter",
  initialState: {
    typeFilter: 'ALL_BT',
    typeFilterData: {value: 'All_BT', label: 'All Orders'},
    statusFilter: 'ALL',
    statusFilterData: {value: 'ALL', label: 'All Orders'},
    timeFilter: timeService.getToday(),
    timeFilterData: {value: 'today', label: 'Today'}
  },
  reducers: {
    setTypeFilter: (state, action) => {
        state.typeFilter = action.payload.value;
        state.typeFilterData = action.payload.data;
    },
    setStatusFilter: (state, action) => {
        state.statusFilter = action.payload.value;
        state.statusFilterData = action.payload.data;
    },
    setTimeFilter: (state, action) => {
        state.timeFilter = action.payload.value;
        state.timeFilterData = action.payload.data
    },
  }
});


export const selectBatteryOrderFilters = (state) => state.batteryOrderFilter;

export const {setTypeFilter, setStatusFilter, setTimeFilter} = BatteryOrderFilterSlice.actions;

export default BatteryOrderFilterSlice.reducer;
import React, {useEffect, useState} from 'react';
import authService from '../../services/auth.service';
import API from '../../services/axios';

const Login = (props) => {

    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);

    const [tries, setTries] = useState(0);
    const [sendingOtp, setSendingOtp] = useState(false);

    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const regex = /^[0-9]*$/;

    useEffect(() => {
        if (!token) {
            setOtpSent(false);
            setError('');
        }
    }, [])

    const submitMobile = () => {
        if (phone.length !== 10) {
            setError('Enter a valid phone number');
        } else if (!regex.test(phone)) {
            setError('Enter a valid phone number');
        } else {
            setError('');
            setLoading(true);
            authService.login({phone: phone}).then(res => {
                setToken(res.data.data.otpToken);
                setLoading(false);
                setOtpSent(true);
            }).catch(error => {
                setError(error.response.data.message);
                setLoading(false);
            })
        }
    }

    const verifyOTP = () => {
        if (otp.length === 6 && regex.test(otp)) {
            setError('')
            setLoading(true);
            authService.verifyOTP(token, otp).then(res => {
                setFullUser();
            }).catch(error => {
                setLoading(false);
                setError(error.response.data.message);
            })
        } else {
            setError('Enter a valid OTP.');
        }
    }

    // set full user
    const setFullUser = () => {
        API.get('user/me').then(res => {
            console.log('setting')
            authService.setFullUser(res.data.data);
            setTimeout(() => {
                setLoading(false);
                props.history.push('/');
            }, 200);
        }).catch(error => {
            console.log(error);
            props.history.push('/');
        })
    }

    const resendOTP = () => {
        if (tries > 2) {
            setError('Too many attempts. Please try later.')
        } else {
            setSuccessMessage('');
            setSendingOtp(true);
            const x = 2 - tries;
            setTries(tries + 1);
            setError(`${x} tries remaining.`)
            API.post('/auth/resend-otp-phone', {phone: phone, type: 'LOGIN'}).then(res => {
                setSendingOtp(false);
                setToken(res.data.data.otpToken);
                setSuccessMessage('OTP Sent Successfully');
            }).catch(error => {
                setSendingOtp(false);
                setError('OTP not sent! some error occurred');
            })
        }
    }

    return (
        <div className="bg-gray-200 w-full h-screen flex justify-center items-center">
            
            { !otpSent && 
            <div className="flex mx-4 border-2 border-gray-300 bg-white flex-col px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
                <img src={process.env.PUBLIC_URL +"/logo_wide.png"} className="w-full mb-4"/>                
                <div className="flex flex-col mb-10">
                    <label htmlFor="phone" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-700 font-medium">Registered Mobile Number</label>
                    <div className="relative">
                        <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full text-gray-600">
                            <div className="ml-3 mr-2">+91</div>
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z" fill="#6B7280"/>
                            </svg>
                        </div>
                        <input autoComplete="off" onChange={(e) => setPhone(e.target.value)} id="phone"  maxLength="10" type="text" name="phone" className="text-sm sm:text-base placeholder-gray-500 pl-20 pr-4 rounded-md border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Phone number" />
                    </div>
                    {error && <p className="text-sm font-semibold px-2 py-0.5 w-max mt-2 bg-red-500 bg-opacity-90 rounded-sm text-white mt-1">{error}</p>}
                </div>
                <div className="flex w-full">
                    <button onClick={submitMobile} className="flex items-center justify-center focus:outline-none text-gray-800 text-sm sm:text-base hover:bg-gray-300 rounded py-2 w-full border-2 border-gray-700 transition duration-150 ease-in">
                        <span className="mr-2 font-semibold uppercase">Send OTP</span>
                        <span>
                            {
                                loading ?
                                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-600"></div>
                                :
                                <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="#333">
                                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            }
                            
                        </span>
                    </button>
                </div>
            </div>}

            { otpSent && 
            <form className="border-2 mx-4 border-gray-300 bg-white flex flex-col px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
                <img src={process.env.PUBLIC_URL +"/logo_wide.png"} className="w-full mb-4"/> 
                <div className="flex flex-col mb-10">
                    <label htmlFor="otp" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-700 font-medium">Enter OTP sent on your mobile number <br/> +91 {phone}.</label>
                    <div className="relative">
                        <input autoComplete="off" onChange={e=>setOtp(e.target.value)} id="otp" maxLength="6" type="text" name="otp" className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Enter OTP" />
                    </div>
                    {error && <p className="text-sm font-semibold px-2 py-0.5 w-max mt-2 bg-red-500 bg-opacity-90 rounded-sm text-white mt-1">{error}</p>}
                </div>
                <div className="flex w-full">
                    <button onClick={verifyOTP} type="button" className="flex items-center justify-center focus:outline-none text-gray-800 font-semibold text-sm sm:text-base hover:bg-gray-300 rounded py-2 w-full border-2 border-gray-700 transition duration-150 ease-in">
                        <span className="mr-2 uppercase">Login</span>
                        <span>
                        {loading ?
                        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-600"></div>
                        :
                        <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        }
                        </span>
                    </button>
                </div>

                <div className="flex flex-col justify-center items-center mt-8">
                    <p className="text-sm text-gray-800 font-medium font-light mb-2">Didn't receive SMS?</p>
                    <div onClick={resendOTP} className="rounded-full bg-secondary-blue cursor-pointer text-white text-sm border-2 border-white px-3 py-1">
                        {sendingOtp ?
                        <div className="animate-spin rounded-full h-3 w-3 border-b-2 border-white"></div>
                         :
                         <span>Resend OTP</span>
                        }
                    </div>
                </div>

                {successMessage && 
                    <p className="mt-4 text-green-700 text-center text-sm font-semibold">{successMessage}</p>
                }
            </form>}

        </div>
    )
}

export default Login;
import React, { useState, useEffect, useRef } from 'react';
import Gmap from './gmap';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import authService from '../../../../services/auth.service';
import registerService from '../../../../services/register.service';
import Header from '../../components/header';

const LocationRegistrationComponent = (props) => {

  let autoComplete;
  const autoCompleteRef = useRef(null);
  const plotNoInputRef = useRef(null);

  // validation schema
  const validationSchema = yup.object().shape({
    plotNo: yup.string().trim().required('House Number / Plot number is required').max(40, 'House no. / Plot no. too long'),
    address: yup.string().trim().required('Address is required').min(5, 'Address too short').max(160, 'Address too long'),
    area: yup.string().trim().required('Area is required').min(3, 'Area too short').max(80, 'Area too long'),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, formState:{ errors }, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [query, setQuery] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const [loc, setLoc] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();

  useEffect(() => {
        if(!scriptLoaded) {
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
            );
        }
  });

  useEffect(() => {
    const data = registerService.getFormData();
    if (!data?.phone || !data?.battery || !data) {
      props.history.push('/register')
    }
  }, []);

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "in" }, fields: ["address_components", "geometry", "formatted_address", "name", "url"], }
    );
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
    setScriptLoaded(true);
  }

  async function handlePlaceSelect(updateQuery) {

    const addressObject = autoComplete.getPlace();
        
    if(!addressObject.geometry) {
      console.log('returning - no address found');
      return;
    }

    updateQuery(addressObject.formatted_address);
    handleAreaUX();

    const stateName = addressObject.address_components.find(g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )).long_name;
    const cityName = addressObject.address_components.find( g => g.types.find( t => t === 'locality') && g.types.find( t => t === 'political' )).long_name;
    const zip = addressObject.address_components.find( g => g.types.find( t => t === 'postal_code' )).long_name;
    const area = addressObject.address_components.find( g => g.types.find( t => t === 'sublocality_level_1' )).long_name;

    setState(stateName);
    setCity(cityName);
    setPincode(zip);

    setValue('address', addressObject.name + ', ' + addressObject.formatted_address);
    setValue('area', area);

    setLoc({lat: addressObject.geometry.location.lat(), lng: addressObject.geometry.location.lng(), url: addressObject.url})

  }

  // to scroll area input into view when place is selected 
  const handleAreaUX = () => {
    document.getElementById('plotNo').focus();
  }

  const onSubmit = (e) => {
      const fullAddress = e.plotNo + ' ,' + e.address;
      if (loc && loc.lat && loc.url && state && city && pincode) {
        setError('');
        setSubmitted(true)
        registerService.setLocation(loc.lat, loc.lng, loc.url, state, city, pincode, fullAddress, e.area);
        
        authService.register(registerService.getFormData()).then(res => {
          props.history.push({pathname : '/register/verify', state: {token: res.data.data.otpToken, phone: registerService.getFormData().phone}});
        }).catch(error => {
          setSubmitted(false);
          setError(error.response.data.message);
        });

      } else {
        setSubmitted(false);
        setError('Please select address on google map.')
      }
  }

  return (
        <div className="bg-gray-100 py-2">
          <Header name='Add your store Location'/>
          <div className="px-4 max-w-6xl mx-auto pb-8">
              <div className="flex flex-col">
                  <label htmlFor="businessName" className="text-sm font-semibold text-gray-600 mb-1">Search your location</label>
                  <div className="flex relative">
                      <input
                          ref={autoCompleteRef}
                          onChange={event => setQuery(event.target.value)}
                          placeholder="Enter your store location"
                          value={query}
                          className="text-sm mt-1 placeholder-gray-500 pl-8 pr-3 rounded-sm border border-gray-400 w-80 py-2 focus:outline-none focus:border-blue-400"
                      />
                      <svg className="absolute top-3 left-2" width="14" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.00001 0C4.02946 0 0 3.37535 0 7.53907C0 8.93152 0.325365 10.3697 1.25906 11.4063L9.00001 20L16.7409 11.4063C17.589 10.4648 18 8.80032 18 7.53907C18 3.37535 13.9706 0 9.00001 0ZM9.00001 4.36645C11.0914 4.36645 12.7874 5.78713 12.7874 7.53905C12.7874 9.291 11.0914 10.7117 9.00001 10.7117C6.90858 10.7117 5.21261 9.291 5.21261 7.53907C5.21261 5.78713 6.90858 4.36645 9.00001 4.36645Z" fill="#9CA3AF"/>
                      </svg>

                  </div>
              </div>

              <div className="my-10 rounded-md border border-gray-300 shadow-sm overflow-hidden">
                  { scriptLoaded &&
                      <Gmap loc={loc} ></Gmap>
                  }
              </div>
              
              <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
                        <div className="flex flex-col">
                            <label htmlFor="plotNo" className="text-sm font-regular text-gray-800 mb-1">House No. / Plot No. / Door No.</label>
                            <input ref={plotNoInputRef} id="plotNo" {...register("plotNo")} type="text" className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                            <p className="text-sm text-red-600 py-1">{errors.plotNo?.message}</p>
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="address" className="text-sm font-regular text-gray-800 mb-1">Address</label>
                            <input id="address" {...register("address")} type="text" className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                            <p className="text-sm text-red-600 py-1">{errors.address?.message}</p>
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="area" className="text-sm font-regular text-gray-800 mb-1">Area</label>
                            <input id="area" {...register("area")} type="text" className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                            <p className="text-sm text-red-600 py-1">{errors.area?.message}</p>
                        </div>
                    </div>

                    <p className="text-sm text-red-500 font-semibold">{error}</p>

                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center my-10 rounded-md border border-gray-200 shadow-sm bg-white p-4">
                      <div>
                        <label className="text-sm font-semibold">Terms & Conditions</label>
                        <p className="text-xs mt-1 text-gray-600">By proceeding, you agree to accept our <span className="text-blue-500">Terms & Conditions</span></p>
                      </div>
                      <button type="submit" className="flex items-center justify-center w-40 bg-indigo-600 text-center mt-4 sm:mt-0 rounded cursor-pointer hover:bg-indigo-700 px-6 py-2 text-white text-base font-semibold">
                        {
                          submitted ? 
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                          :
                          <span>Send OTP</span>
                        }
                      </button>
                    </div>
              </form>
          </div>
        </div>
  )
}

export default LocationRegistrationComponent;
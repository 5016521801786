import axios from 'axios';
import authService from './auth.service';

let API = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
})

// add auth header
API.interceptors.request.use(async function (config) {
    const user = authService.getCurrentUser();
    if (user && user.accessToken) {
        if (config.method !== 'OPTIONS') {
            config.headers.authorization = `Bearer ${user.accessToken}`;
        }
    }
    return config;
}, function (error) {
    console.error('axios' + error)
})


// handle invalid / expired token
const responseErrorHandler = error => {
    if (error.response.status === 401) {
        // handle token expired / unauth
        authService.logout();
    }
    return Promise.reject(error);
}
const responseSuccessHandler = response => {
    return response;
};

API.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
)

export default API;
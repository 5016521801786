import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import API from '../../../../services/axios';
import Header from '../../components/header';
import './products.css';
import registerService from '../../../../services/register.service';

const ProductBattery = ({register, errors, data, brands, mainIndex}) => {

    const [activated, setActivated] = useState(false);

    const handleActivation = (e) => {
        setActivated(e.target.checked);
    }

    return(
        <div className="border border-blue-200 bg-blue-50 select-none">
            <fieldset>
                <div className="">
                    <label className="inline-flex p-4 items-center cursor-pointer">
                            <input {...register(`battery.${mainIndex}.product`)} type="checkbox" value={data.id} onChange={handleActivation} className="opacity-0 absolute h-6 w-6 check-primary cursor-pointer" />
                            <div className="bg-white border-2 rounded-full border-gray-400 w-6 h-6 mr-2 focus-within:border-blue-500"></div>
                            <span className="ml-1 uppercase font-bold">{data.productName}</span>
                    </label>
                </div>
                <div className={`${activated ? '' : 'opacity-40'}`}>
                    <div className="px-4 py-3 grid grid-cols-1 sm:grid-cols-3 gap-4 border-t border-gray-300 border-dashed">
                        {brands.map((i,index) => {
                            return (
                                <div key={index}>
                                    <label className="flex items-center">
                                        <input {...register(`battery.${mainIndex}.brand.${index}`)} disabled={!activated} value={i.id} type="checkbox" className="opacity-0 absolute h-0 w-0 check-secondary" />
                                        <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 mr-2 focus-within:border-blue-500 flex items-center justify-center">
                                            <svg className="hidden" width="14" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.7069 0.293C13.8944 0.480528 13.9997 0.734836 13.9997 1C13.9997 1.26516 13.8944 1.51947 13.7069 1.707L5.70692 9.707C5.51939 9.89447 5.26508 9.99979 4.99992 9.99979C4.73475 9.99979 4.48045 9.89447 4.29292 9.707L0.292919 5.707C0.110761 5.5184 0.00996641 5.2658 0.0122448 5.0036C0.0145233 4.7414 0.119692 4.49059 0.3051 4.30518C0.490508 4.11977 0.741321 4.0146 1.00352 4.01233C1.26571 4.01005 1.51832 4.11084 1.70692 4.293L4.99992 7.586L12.2929 0.293C12.4804 0.105529 12.7348 0.000213623 12.9999 0.000213623C13.2651 0.000213623 13.5194 0.105529 13.7069 0.293Z" fill="#3F3F46"/>
                                            </svg>
                                        </div>
                                        <span className="ml-1">{i.name}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {errors.battery && <p className="text-sm text-red-600 py-1 pl-4">{errors?.battery[mainIndex]?.message}</p>}
            </fieldset>
            
        </div>
    )
}

const ProductInverter = ({register, errors, data, brands, mainIndex}) => {

    const [activated, setActivated] = useState(false);

    const handleActivation = (e) => {
        setActivated(e.target.checked);
    }

    return(
        <div className="border border-blue-200 bg-blue-50 select-none">
            <fieldset>
                <div className="">
                    <label className="inline-flex p-4 items-center cursor-pointer">
                            <input {...register(`inverter.${mainIndex}.product`)} type="checkbox" value={data.id} onChange={handleActivation} className="opacity-0 absolute h-6 w-6 check-primary cursor-pointer" />
                            <div className="bg-white border-2 rounded-full border-gray-400 w-6 h-6 mr-2 focus-within:border-blue-500"></div>
                            <span className="ml-1 uppercase font-bold">{data.productName}</span>
                    </label>
                </div>
                <div className={`${activated ? '' : 'opacity-40'}`}>
                    <div className="px-4 py-3 grid grid-cols-1 sm:grid-cols-3 gap-4 border-t border-gray-300 border-dashed">
                        {brands.map((i,index) => {
                            return (
                                <div key={index}>
                                    <label className="flex items-center">
                                        <input {...register(`inverter.${mainIndex}.brand.${index}`)} disabled={!activated} value={i.id} type="checkbox" className="opacity-0 absolute h-0 w-0 check-secondary" />
                                        <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 mr-2 focus-within:border-blue-500 flex items-center justify-center">
                                            <svg className="hidden" width="14" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.7069 0.293C13.8944 0.480528 13.9997 0.734836 13.9997 1C13.9997 1.26516 13.8944 1.51947 13.7069 1.707L5.70692 9.707C5.51939 9.89447 5.26508 9.99979 4.99992 9.99979C4.73475 9.99979 4.48045 9.89447 4.29292 9.707L0.292919 5.707C0.110761 5.5184 0.00996641 5.2658 0.0122448 5.0036C0.0145233 4.7414 0.119692 4.49059 0.3051 4.30518C0.490508 4.11977 0.741321 4.0146 1.00352 4.01233C1.26571 4.01005 1.51832 4.11084 1.70692 4.293L4.99992 7.586L12.2929 0.293C12.4804 0.105529 12.7348 0.000213623 12.9999 0.000213623C13.2651 0.000213623 13.5194 0.105529 13.7069 0.293Z" fill="#3F3F46"/>
                                            </svg>
                                        </div>
                                        <span className="ml-1">{i.name}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {errors.inverter && <p className="text-sm text-red-600 py-1 pl-4">{errors?.inverter[mainIndex]?.message}</p>}
            </fieldset>
            
        </div>
    )
}


const ProductsRegistrationPage = (props) => {

    const [productType, setProductType] = useState([]);
    const [brands, setBrands] = useState([]);
    const [mainError, setMainError] = useState(null);

    const validationSchema = yup.object().shape({
        battery: yup.array().of(
            yup.object().shape({product: yup.string(), brand: yup.array()}).test(
            'BatterySelectedButNoBrand',
            'Please Select at least one brand',
            (obj) => {
                    if(obj.product && obj.product != 'false') {
                        const x = obj.brand.filter(i => i !== false)
                        if (x.length > 0) {
                            return true;
                        } else{ 
                            return false;
                        }
                    }
                    return true;
            })
        ),
        inverter: yup.array().of(
            yup.object().shape({product: yup.string(), brand: yup.array()}).test(
            'BatterySelectedButNoBrand',
            'Please Select at least one brand',
            (obj) => {
                    if(obj.product && obj.product != 'false') {
                        const x = obj.brand.filter(i => i !== false)
                        if (x.length > 0) {
                            return true;
                        } else{ 
                            return false;
                        }
                    }
                    return true;
            })
        ),
    });

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        const data = registerService.getFormData();
        if (!data?.phone || !data) {
        props.history.push('/register')
        }
    })

    useEffect(() => {

        API.get('/product').then(res => {
            res.data.data.sort((a, b) => a.productName.localeCompare(b.productName))
            setProductType(res.data.data);
        }).catch(err => {
            console.log(err);
        });

        API.get('/brand').then(res => {
            setBrands(res.data.data);
        })
        .catch(err => {
            console.log(err);
        })
    }, [])

    const checkNotEmpty = (battery, inverter) => {
        const x = battery.filter(i => i.product !== 'false');
        const y = inverter.filter(i => i.product !== 'false');

        if(x.length === 0 && y.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    const onSubmit = (e) => {
        if(checkNotEmpty(e.battery, e.inverter)) {
            setMainError(null);
            registerService.setProducts(e);
            props.history.push('/register/services');
        } else {
            setMainError('Please select at lease one product')
        }
    }


    return (
        <div>
            <div className="bg-gray-100 py-2">
                <Header name='Products you Offer'/>
                <div className="px-4 max-w-6xl mx-auto pb-8">
                        <div className="bg-white p-3 sm:p-4 rounded-md shadow-sm mb-4">
                            <h4 className="text-base font-regular mb-6 font-bold">Please select the products you offer</h4>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="font-bold my-2 px-2 py-1 bg-green-100">Battery</div>
                                <div className="flex flex-col gap-2">
                                    {
                                        productType.filter(i => i.productType !== 'INVERTER').map((i,index) => {
                                            return(
                                                <ProductBattery register={register} data={i} key={index} brands={brands} mainIndex={index} errors={errors} />
                                            )
                                        })
                                    }
                                </div>
                                <div className="my-10">
                                    <div className="font-bold my-2 px-2 py-1 bg-green-100">Inverter</div>
                                    {
                                        productType.filter(i => i.productType === 'INVERTER').map((i,index) => {
                                            return(
                                                <ProductInverter register={register} data={i} key={index} brands={brands} mainIndex={index} errors={errors} />
                                            )
                                        })
                                    }
                                </div>

                                <div className="pt-4 flex justify-end">
                                    <button type="submit" className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded">Save & Continue</button>
                                </div>
                            </form>

                            {mainError && <p className="text-base text-red-600 font-bold py-1">{mainError}</p>}
                        </div>
                </div>
            </div>
        </div>
    )
}

export default ProductsRegistrationPage;
const getAllTime = () => {
    return {from: new Date("September 01, 2010 00:00:00").getTime(), to: Date.now()}
}

const getToday = () => {
    const today = new Date();
    return {from: new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime(), to: Date.now()};
}

const getThisWeek = () => {
    const today = new Date();
    return {from: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).getTime(), to: Date.now()}
}

const getLastWeek = () => {
    const today = new Date();
    const lastWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).getTime();
    const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14).getTime();
    return {from: lastWeekStart, to: lastWeekEnd};
}

const getThisMonth = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).getTime()
    return {from: startOfMonth, to: today.getTime()}
}

const getLastMonth = () => {
    const date = new Date();
    const lastMonthFirstDay = new Date(date.getFullYear(), date.getMonth()-1, 1).getTime();
    const lastMonthLastDay = new Date(date.getFullYear(), date.getMonth(), 0).getTime();
    return {from: lastMonthFirstDay, to: lastMonthLastDay}
}

const getThisYear = () => {
    const date = new Date();
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1).getTime()
    return {from: firstDayOfYear, to: new Date().getTime()}
}

const getLastYear = () => {
    const date = new Date();
    const firstDayOfLastYear = new Date(date.getFullYear() -1, 0, 1).getTime();
    const lastDayOfLastYear = new Date(date.getFullYear()-1, 12, 0 ).getTime();
    return {from: firstDayOfLastYear, to: lastDayOfLastYear}
}

const getTimefromSelectedDateRange = (range) => {
    const startDate = new Date(range.from.year, range.from.month -1, range.from.day).getTime();
    const endDate = new Date(range.to.year, range.to.month -1, range.to.day, 23,59,59).getTime();
    return {from: startDate, to: endDate}
}


export default {
    getAllTime,
    getToday,
    getThisWeek,
    getLastWeek,
    getThisMonth,
    getLastMonth,
    getThisYear,
    getLastYear,
    getTimefromSelectedDateRange
}
import API from "./axios";

const register = (data) => {
    return API.post('/auth/register', data);
}

const login = (data) => {
    return API.post('/auth/login', data)
}

const verifyOTP = (token, otp) => {
    return API.post('/auth/login-verify', {otpToken: token, otp: otp})
    .then(response => {
        if (response.data.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data.data));
        }
        return response.data;
    })
}

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem('fullUser');
    window.location.reload();
}

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
}

const getFullUser = () => {
    return JSON.parse(localStorage.getItem('fullUser'));
}
const setFullUser = (data) => {
    localStorage.setItem('fullUser', JSON.stringify(data));
}

export default {
    register,
    login,
    verifyOTP,
    logout,
    getCurrentUser,
    getFullUser,
    setFullUser
  };
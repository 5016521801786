import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import authService from '../../../services/auth.service';
import './appNavbar.css';

const AppNavbar = (props) => {

    useEffect(() => {
        setUser(authService.getFullUser())
    }, [])

    const routes = [
        {name: 'My Orders', url: '/orders'},
        {name: 'My Invoices', url: '/invoices'},
        {name: 'Order Summary', url: '/order-summary'},
        {name: 'Update Stock', url: '/inventory'}
    ]

    const [user, setUser] = useState('');

    const [smMenuOpen, setSmMenuOpen] = useState(false);

    const logout = () => {
        authService.logout();
    }

    return(
        <nav className="bg-white shadow select-none sticky top-0 z-10 relative">
            <div className="max-w-7xl mx-auto px-4">
				<div className="flex justify-between items-center">
					<div className="flex">
							<a href="/" className="flex items-center py-4 mr-8">
								<span className="font-bold text-gray-800 text-xl lg:text-2xl">Batterywale.com</span>
							</a>

                            <div className="hidden md:flex">
                                <NavLink to='/' exact={true} activeClassName='border-indigo-600' className="text-xs lg:text-sm px-1 h-full border-b-2 border-transparent flex items-center mr-2 lg:mr-4">Dashboard</NavLink>
                                {routes.map((i, index) => (
                                    <NavLink to={i.url} key={index} activeClassName='border-indigo-600'  className="text-xs lg:text-sm px-1 h-full border-b-2 border-transparent flex items-center mr-2 lg:mr-4">{i.name}</NavLink>
                                ))}
                            </div>
					</div>
                    
                    <div className="hidden md:flex items-center text-gray-700 hover:text-indigo-900 dropdown relative">
                        <div className={`h-2 w-2 ring rounded-full my-auto mr-3 mt-2 ${props.socketConnectionStatus ? 'ring-green-500 bg-green-300' : 'ring-yellow-500 bg-yellow-300'}`}></div>
                        <button className="flex cursor-pointer justify-center leading-5 text-gray-800 hover:text-gray-500 transition duration-150 ease-in-out active:text-green-800" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="main-dropdown">
                            <span className="text-xs lg:text-sm mr-2 font-semibold hidden lg:block">{user?.phone ? `+91 ${user.phone}` : 'Account & Settings'}</span>
                            <svg className="lg:hidden" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 21V19C20 16.7909 18.2091 15 16 15H8C5.79086 15 4 16.7909 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="20" height="19" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.5 9L12.5 16L5.5 9" stroke="#3F3F46" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        <div className="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
                            <div className="absolute right-0 w-56 mt-8 origin-top-right bg-white border border-gray-200 divide-y divide-gray-200 rounded-b-md shadow-md outline-none" aria-labelledby="lg-menu" role="menu">
                                <div className="py-1">
                                    <NavLink activeClassName="bg-gray-100 text-indigo-500" to="/account" tabIndex="0" className="text-gray-700 hover:bg-gray-100 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Account settings</NavLink>
                                    <NavLink activeClassName="bg-gray-100 text-indigo-500" to="/help" tabIndex="1" className="text-gray-700 hover:bg-gray-100 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Support</NavLink>
                                </div>
                                <div className="py-1">
                                    <a onClick={logout} tabIndex="3" className="text-gray-700 hover:text-red-500 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Sign out</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex md:hidden items-center cursor-pointer text-gray-700 svg" onClick={() => setSmMenuOpen(!smMenuOpen)}>
                        <div className={`h-2 w-2 ring rounded-full my-auto mr-3 mt-2 ${props.socketConnectionStatus ? 'ring-green-500 bg-green-300' : 'ring-yellow-500 bg-yellow-300'}`}></div>
                        {!smMenuOpen ? 
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6H20M4 12H20M4 18H20" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        :
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 18L18 6M6 6L18 18" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        }
                    </div>
				</div>
			</div>


            {/* menu for mobile view */}
            <div className={`md:hidden absolute right-0 left-0 w-full opacity-0 invisible transition-all duration-300 transform origin-top -translate-y-2 scale-95 ${smMenuOpen? 'open':''}`}>
                        <div className="px-4 py-4 w-full origin-top bg-white border border-gray-200 rounded-b-md shadow-md outline-none flex-col" aria-labelledby="sm-menu" role="menu">
                            <NavLink onClick={() => setSmMenuOpen(false)} to="/" activeClassName='text-gray-800' className="flex items-center py-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 19V13C9 11.8954 8.10457 11 7 11H5C3.89543 11 3 11.8954 3 13V19C3 20.1046 3.89543 21 5 21H7C8.10457 21 9 20.1046 9 19ZM9 19V9C9 7.89543 9.89543 7 11 7H13C14.1046 7 15 7.89543 15 9V19M9 19C9 20.1046 9.89543 21 11 21H13C14.1046 21 15 20.1046 15 19M15 19V5C15 3.89543 15.8954 3 17 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H17C15.8954 21 15 20.1046 15 19Z" stroke="#4F46E5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className='ml-4 font-semibold'>Dashboard</span>
                            </NavLink>
                            <NavLink onClick={() => setSmMenuOpen(false)} to="/orders" activeClassName='text-gray-800' className="flex items-center py-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9998 14.9998L12.9998 19.9998L8.99983 8.99976L19.9998 12.9998L14.9998 14.9998ZM14.9998 14.9998L19.9998 19.9998M7.18806 2.23828L7.96452 5.13606M5.13606 7.96448L2.23828 7.18802M13.9495 4.05005L11.8282 6.17137M6.17146 11.8281L4.05014 13.9494" stroke="#4F46E5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className='ml-4 font-semibold'>My Orders</span>
                            </NavLink>
                            <NavLink onClick={() => setSmMenuOpen(false)} to="/invoices" activeClassName='text-gray-800' className="flex items-center py-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 12L11 14L15 10M20.6179 5.98434C20.4132 5.99472 20.2072 5.99997 20 5.99997C16.9265 5.99997 14.123 4.84453 11.9999 2.94434C9.87691 4.84446 7.07339 5.99985 4 5.99985C3.79277 5.99985 3.58678 5.9946 3.38213 5.98422C3.1327 6.94783 3 7.95842 3 9.00001C3 14.5915 6.82432 19.2898 12 20.622C17.1757 19.2898 21 14.5915 21 9.00001C21 7.95847 20.8673 6.94791 20.6179 5.98434Z" stroke="#4F46E5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className='ml-4 font-semibold'>My Invoices</span>
                            </NavLink>
                            <NavLink onClick={() => setSmMenuOpen(false)} to="/order-summary" activeClassName='text-gray-800' className="flex items-center py-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6C4 4.89543 4.89543 4 6 4H8C9.10457 4 10 4.89543 10 6V8C10 9.10457 9.10457 10 8 10H6C4.89543 10 4 9.10457 4 8V6Z" stroke="#4F46E5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14 6C14 4.89543 14.8954 4 16 4H18C19.1046 4 20 4.89543 20 6V8C20 9.10457 19.1046 10 18 10H16C14.8954 10 14 9.10457 14 8V6Z" stroke="#4F46E5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4 16C4 14.8954 4.89543 14 6 14H8C9.10457 14 10 14.8954 10 16V18C10 19.1046 9.10457 20 8 20H6C4.89543 20 4 19.1046 4 18V16Z" stroke="#4F46E5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14 16C14 14.8954 14.8954 14 16 14H18C19.1046 14 20 14.8954 20 16V18C20 19.1046 19.1046 20 18 20H16C14.8954 20 14 19.1046 14 18V16Z" stroke="#4F46E5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                <span className='ml-4 font-semibold'>Order Summary</span>
                            </NavLink>
                            <NavLink onClick={() => setSmMenuOpen(false)} to="/inventory" activeClassName='text-gray-800' className="flex items-center py-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 4V9H4.58152M19.9381 11C19.446 7.05369 16.0796 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9M4.58152 9H9M20 20V15H19.4185M19.4185 15C18.2317 17.9318 15.3574 20 12 20C7.92038 20 4.55399 16.9463 4.06189 13M19.4185 15H15" stroke="#4F46E5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                <span className='ml-4 font-semibold'>Update Stock</span>
                            </NavLink>
                            <NavLink onClick={() => setSmMenuOpen(false)} to="/account" activeClassName='text-gray-800' className="flex items-center py-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                <path d="M20.1212 17.8969C19.679 16.8496 19.0374 15.8984 18.2321 15.0961C17.4292 14.2915 16.4781 13.65 15.4313 13.207C15.4219 13.2023 15.4126 13.2 15.4032 13.1953C16.8633 12.1406 17.8126 10.4227 17.8126 8.48438C17.8126 5.27344 15.211 2.67188 12.0001 2.67188C8.78912 2.67188 6.18756 5.27344 6.18756 8.48438C6.18756 10.4227 7.13678 12.1406 8.59693 13.1977C8.58756 13.2023 8.57818 13.2047 8.56881 13.2094C7.51881 13.6523 6.57662 14.2875 5.76803 15.0984C4.96344 15.9013 4.32194 16.8524 3.87896 17.8992C3.44378 18.924 3.20908 20.0228 3.18756 21.1359C3.18693 21.161 3.19132 21.1858 3.20046 21.2091C3.20961 21.2324 3.22332 21.2537 3.24079 21.2716C3.25826 21.2895 3.27915 21.3037 3.30221 21.3134C3.32527 21.3231 3.35004 21.3281 3.37506 21.3281H4.78131C4.88443 21.3281 4.96646 21.2461 4.96881 21.1453C5.01568 19.3359 5.74225 17.6414 7.02662 16.357C8.35553 15.0281 10.1204 14.2969 12.0001 14.2969C13.8797 14.2969 15.6446 15.0281 16.9735 16.357C18.2579 17.6414 18.9844 19.3359 19.0313 21.1453C19.0337 21.2484 19.1157 21.3281 19.2188 21.3281H20.6251C20.6501 21.3281 20.6749 21.3231 20.6979 21.3134C20.721 21.3037 20.7419 21.2895 20.7593 21.2716C20.7768 21.2537 20.7905 21.2324 20.7997 21.2091C20.8088 21.1858 20.8132 21.161 20.8126 21.1359C20.7891 20.0156 20.5571 18.9258 20.1212 17.8969ZM12.0001 12.5156C10.9243 12.5156 9.91178 12.0961 9.15006 11.3344C8.38834 10.5727 7.96881 9.56016 7.96881 8.48438C7.96881 7.40859 8.38834 6.39609 9.15006 5.63437C9.91178 4.87266 10.9243 4.45312 12.0001 4.45312C13.0758 4.45312 14.0883 4.87266 14.8501 5.63437C15.6118 6.39609 16.0313 7.40859 16.0313 8.48438C16.0313 9.56016 15.6118 10.5727 14.8501 11.3344C14.0883 12.0961 13.0758 12.5156 12.0001 12.5156Z" fill="#4F46E5"/>
                                </g>
                                    <defs>
                                    <clipPath id="clip0">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>

                                <span className='ml-4 font-semibold'>Account Settings</span>
                            </NavLink>
                            <div onClick={logout} className="flex items-center py-2 mt-4 text-white bg-red-500 rounded justify-center">
                                <span className='text-md'>Sign out</span>
                            </div>                    
                        </div>
                </div>
        </nav>
    )
}

export default AppNavbar;
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'

import Header from '../../components/header';
import registerService from '../../../../services/register.service';

const TechnicianRegisterPage = (props) => {

    // Regex
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const noWhiteSpaceRegEx = /^\S*$/;
    const [technicianError, setTechnicianError] = useState('');  

    const validationSchema = yup.object().shape({
        technician: yup.array().of(
            yup.object().shape({
                name: yup.string().trim().ensure(),
                phone: yup.string().trim().ensure(),
            })
            .test(
                'techTest',
                'Name is too short.',
                (obj) => {
                    if (obj.name || obj.phone) {
                        if(obj.name.length < 3 && obj.phone) {
                            return false;
                        } else {
                            return true;
                        }
                        
                    } else {
                        return true;
                    }
                    
                }
            )
            .test(
                'techTest',
                'Phone number is not valid.',
                (obj) => {
                    if (obj.name || obj.phone) {
                        if(obj.phone.length === 10 && phoneRegExp.test(obj.phone) && noWhiteSpaceRegEx.test(obj.phone)) {
                            return true;
                        } else {
                            return false;
                        }
                        
                    } else {
                        return true;
                    }
                    
                }
            )
        )
    })

    useEffect(() => {
        const data = registerService.getFormData();
        if (!data?.phone || !data) {
            props.history.push('/register')
        }
    })

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data) => {
        let isValid = false;
        data.technician.forEach(i => {
            if (i.name.length > 0) {
                isValid = true
            }
        })
        if(!isValid) {
            setTechnicianError('At least 1 technician is required');
        } else {
            setTechnicianError('');
            registerService.setTechnician(data);
            props.history.push('/register/products');
        }
    }

    return (
        <div>
            <div className="bg-gray-100 py-2">
                <Header name='Technician Details'/>
                <div className="px-4 max-w-6xl mx-auto pb-8">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bg-white p-3 sm:p-4 rounded-md shadow-sm mb-4">
                            <h4 className="text-base font-regular">Please enter service technician details.</h4>
                            <p className="text-xs font-medium text-gray-400 mb-6">Minimum 1 service technician is required</p>

                            {[1,1,1,1,1].map((i,index) => {
                                const fieldname = `technician[${index}]`;
                                return (
                                    <fieldset name={fieldname} key={fieldname} className="mb-5 border py-2 px-2 bg-green-100 rounded">
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-2">
                                            <label className="flex flex-col">
                                                <span className="text-sm font-regular text-gray-800 mb-1">{index + 1} Technician Name</span>
                                                <input {...register(`${fieldname}.name`)}  maxLength="100" type="text" className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                                            </label>
                                            <label className="flex flex-col">
                                                <span className="text-sm font-regular text-gray-800 mb-1">Phone Number</span>
                                                <input {...register(`${fieldname}.phone`)} maxLength="10" type="text" className="uppercase text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 w-full py-1 focus:outline-none focus:border-blue-400" />
                                            </label>
                                        </div>
                                        <p className="text-sm text-red-600 py-1">{errors.technician?.[index]?.message}</p>
                                    </fieldset>
                                )
                            })}

                            <p className="text-md text-red-600 py-1">{technicianError}</p>
                        </div>

                        <div className="pt-4 flex justify-end">
                            <button type="submit" className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded">Save & Continue</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )    
} 

export default TechnicianRegisterPage;
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import API from '../../services/axios';



// query params
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const UserApproveComponent = (props) => {

    const [cancelled, setCancelled] = useState(false);
    const [user, setUser] = useState(null);
    const [submitted, setSubmitted] = useState(null);
    const [error, setError] = useState('');

    const [invalidQuery, setInvalidQuery] = useState(false);

    let query = useQuery();

    useEffect(() => {
        if (query.get('id') && query.get('token')) {
            // get data
            setError('');
            API.post('/user/admin-view',
            {
                "userId": query.get('id'),
                "token": query.get('token')
            }).then(res => {
                setUser(res.data.data)
            }).catch(error => {
                setError('Invalid Request!')
            });
        } else {
            setInvalidQuery(true);
        }
    }, [])

    const CancelOperation = () => {
        setCancelled(true);
    }

    const approveUser = () => {
        if (submitted) return;
        setSubmitted(true);
        setError('');
        API.post('/user/admin-view/approve', {
            userId: query.get('id'),
            token: query.get('token'),
            approve: true
        }).then(res => {
            setUser(res.data.data);
            setSubmitted(false);
        }).catch(error => {
            setError('Some error occurred! Please try again. [' + error?.response.data + ']');
        })
    }


    const UserApproved = () => {
        return (
            <div className="px-2">
                    <div className="border border-gray-300 rounded-md overflow-hidden w-full md:w-2/3 lg:w-6/12 xl:5/12 mx-auto mt-20">
                        <div className="px-4 py-4 bg-gray-100 flex items-center justify-between">
                            <div className="text-md font-semibold text-gray-700">User Approved</div>

                            <svg className="text-green-600" width="28" height="28" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M39.0002 68.64C57.9546 68.64 73.3202 53.2744 73.3202 34.32C73.3202 15.3656 57.9546 0 39.0002 0C20.0458 0 4.68018 15.3656 4.68018 34.32C4.68018 44.9926 9.55174 54.5274 17.1925 60.822C17.2047 60.832 17.1939 60.8515 17.1789 60.8464C17.1698 60.8433 17.1602 60.8501 17.1602 60.8598V71.5042C17.1602 74.4834 20.3004 76.4171 22.9607 75.076L34.9722 69.0212C35.6356 68.6867 36.3788 68.5494 37.1207 68.5894C37.743 68.623 38.3696 68.64 39.0002 68.64Z" fill="currentColor"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M50.3012 24.3464C51.3603 25.1585 51.5625 26.6745 50.7531 27.7357L38.7428 43.4829C38.3193 44.0382 37.6774 44.3832 36.9817 44.4295C36.2861 44.4758 35.6044 44.2189 35.1114 43.7246L27.4884 36.0815C26.5456 35.1363 26.5456 33.6064 27.4884 32.6612C28.4346 31.7124 29.9715 31.7124 30.9177 32.6612L36.5784 38.3368L46.9033 24.7994C47.7155 23.7344 49.2382 23.5314 50.3012 24.3464Z" fill="white"/>
                            </svg>

                        </div>
                    </div>
            </div>
        )
    }

    if (user && user.isApproved) {
        return (
            <UserApproved></UserApproved>
        )
    }
    else if (user && !user.isApproved) {
        return (
            <div className="bg-gray-200 min-h-screen">
                <div className="px-8 py-4 bg-white">
                    <h1 className="text-2xl text-center font-bold">Batterywale Admin</h1>
                </div>
                {
                    !cancelled && 
                    <div className="px-2">
                        <div className="border border-gray-300 rounded-md overflow-hidden w-full md:w-2/3 lg:w-6/12 xl:5/12 mx-auto mt-20">
                            <div className="bg-white px-4 py-4 border-b border-gray-200">
                                <h2 className="text-sm font-semibold">Approve User</h2>
                            </div>
                            <div className="flex bg-gray-100 flex-col divide-y py-2">
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">Business Name</div>   
                                    <div className="w-2/3 uppercase">{user.businessName}</div> 
                                </div>
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">Email</div>   
                                    <div className="w-2/3 truncate">{user.email}</div> 
                                </div>
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">Phone Number</div>   
                                    <div className="w-2/3">+91 {user.phone}</div> 
                                </div>
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">GST Number</div>   
                                    <div className="w-2/3 uppercase">{user.gstNo}</div> 
                                </div>
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">Pincode</div>   
                                    <div className="w-2/3">{user.pincode}</div> 
                                </div>
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">Area</div>   
                                    <div className="w-2/3">{user.area}</div> 
                                </div>
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">City</div>   
                                    <div className="w-2/3 uppercase">{user.city}</div> 
                                </div>
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">State</div>   
                                    <div className="w-2/3 uppercase">{user.state}</div> 
                                </div>
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">Address</div>   
                                    <div className="w-2/3">{user.address}</div> 
                                </div>
                                <div className="px-4 py-2 flex text-xs">
                                    <div className="w-1/3">Location</div>   
                                    <div className="w-2/3 truncate hover:text-blue-600">
                                        <a href={`${user.locationUrl}`} target="_blank" rel="noreferrer noopener">{user.locationUrl}</a>
                                    </div> 
                                </div>
                            </div>
                            <div className="bg-white px-4 py-4 border-t border-gray-200 flex justify-between select-none">
                                <div onClick={CancelOperation} className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold px-3 rounded-sm py-1 text-sm border border-gray-300 cursor-pointer">Cancel</div>
                                <div onClick={approveUser} className="bg-blue-100 hover:bg-blue-200 text-blue-600 font-bold px-3 rounded-sm py-1 text-sm border border-blue-300 cursor-pointer">
                                    {submitted ?
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-500"></div>
                                    :
                                    <span>Approve User</span>}
                                </div>
                            </div>
                            {error && <div className="bg-white px-4 py-1 border-t border-gray-200 flex justify-between select-none">
                                <p className="text-sm text-red-500">{error}</p>
                            </div>}
                        </div>
                    </div>
                }
                {
                    cancelled &&
                    <div className="px-2">
                        <div className="border border-gray-300 rounded-md overflow-hidden w-full md:w-2/3 lg:w-6/12 xl:5/12 mx-auto mt-20">
                            <div className="px-4 py-4 bg-gray-100 flex justify-between">
                                <div className="text-sm font-semibold text-gray-700">Operation Cancelled</div>
                                <div onClick={() => {setCancelled(false)}} className="text-xs text-gray-600 w-24 py-1 rounded-full bg-gray-200 hover:bg-gray-300 cursor-pointer text-center">Undo Cancel</div>
                            </div>
                            
                        </div>
                    </div>
                }
            </div>
        );
    }
    else if (!user) {
        return (
            <div className="p-4">{
                invalidQuery ? 
                <div className="text-red-500 font-semibold text-sm">
                    Bad Request --{'>'} Invalid Request : <code>[user id or token not found]</code>
                </div>
                :
                <div>
                    {error ?
                        <p className="text-red-500 font-semibold">{error}</p>
                        :
                        <div className="flex items-center">
                            <span className="mr-4 font-semibold">Loading</span>
                            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-500"></div>
                        </div>
                    }
                </div>
            }</div>
        )
    }
}

export default UserApproveComponent;
import React, { useEffect, useRef } from 'react';

const containerStyle = {
    width: '100%',
    height: '450px'
};


const Gmap = (props) => {

    let map = useRef();
    let marker = useRef();
    const mapContainerRef = useRef();

    const mapLoaded = useRef(false);

    const mapProps = {
        center: new window.google.maps.LatLng(20.5937, 78.9629),
        zoom: 5,
    }

    useEffect(() => {
        if(!mapLoaded.current) {
            map.current = new window.google.maps.Map(mapContainerRef.current, mapProps);
            marker.current = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(20.5937, 78.9629),
                map: map.current,
                draggable: false
            }); 
            mapLoaded.current = true;
            marker.current.addListener('dragend', handleMarkerDragEnd);
        }

        if (props.loc && props.loc?.lat && props.loc?.lng) {
            handleChange(props.loc);
        }
    }, [props])


    const handleChange = (data) => {
        var Latlng = new window.google.maps.LatLng(data.lat, data.lng);
        marker.current.setPosition(Latlng);
        marker.current.setAnimation(window.google.maps.Animation.Drop);
        map.current.setCenter(Latlng);
        map.current.setZoom(15);
    }

    const handleMarkerDragEnd = (e) => {
        console.log(e.latLng.lat(), e.latLng.lng());
    }


    return (
        <div style={containerStyle} className="h-40">
            <div ref={mapContainerRef} className="h-full w-full"></div>
        </div>
    )
   
};

export default Gmap;
import { configureStore } from "@reduxjs/toolkit";
import BatteryOrderFilterReducer from "./slices/batteryOrderFilterSlice";
import InverterOrderFilterReducer from "./slices/inverterOrderFilterSlice";
import ServiceOrderFilterReducer from "./slices/serviceOrderFilterSlice";

export const Store = configureStore({
    reducer: {
        batteryOrderFilter: BatteryOrderFilterReducer,
        inverterOrderFilter: InverterOrderFilterReducer,
        serviceOrderFilter: ServiceOrderFilterReducer
    }
});
import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="bg-white shadow">
            <div className="max-w-6xl mx-auto px-4">
				<div className="flex justify-between items-center">
					<div className="flex">
						<div>
							<a href="/register" className="flex items-center py-4">
								<span className="font-bold text-primary-blue text-xl sm:text-2xl">Batterywale.com</span>
							</a>
						</div>
					</div>
                    <NavLink to="/login" className="text-xs sm:text-base cursor-pointer hover:text-primary-blue font-regular">Continue to Login</NavLink>
				</div>
			</div>
        </nav>
    )
}

export default Navbar;
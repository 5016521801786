import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundReg = () => {
    return (
        <div className="max-w-7xl mx-auto px-4 pb-14">
            <div className="flex flex-col items-center py-14 px-4">
                <h1 className="text-8xl font-bold text-gray-400">404</h1>
                <p className="text-xs md:text-lg text-center mt-4 w-full md:w-1/2">Sorry. The page you are looking for doesn't exist. Either it was removed, or you mistyped the link.</p>
                <Link to="/login" className="mt-12 px-6 py-2 bg-indigo-600 hover:bg-indigo-700 cursor-pointer text-white rounded-md">Login</Link>
            </div>
        </div>
    )
}

export default NotFoundReg;
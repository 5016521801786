import React, { useEffect, useState } from 'react';
import API from '../../../../services/axios';
import Header from '../../components/header';


const VerifyPhone = (props) => {

    useEffect(() => {
        if (!props.location.state || !props.location.state.token) {
            console.log('not present')
            props.history.push('/register')
        } else {
            setToken(props.location.state.token);
        }
    }, [props])

    const [_token, setToken] = useState(null);
    const [value, setValue] = useState('');
    const [_error, setError] = useState('');
    const [tries, setTries] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const regex = /^[0-9]*$/;
    const handleSubmit = () => {
        if (value.length !== 6) {
            setError('Enter a valid 6 digit OTP');
        } else if (!regex.test(value)) {
            setError('Enter a valid OTP');
        } else {
            setSubmitted(true);
            setError('');
            API.post('/auth/login-verify', {otpToken: _token, otp: value}).then(res => {
                props.history.push('/register/success');
            }).catch(error => {
                setError(error.response.data.message);
                setSubmitted(false);
            })
        }
    }

    const resendOTP = () => {
        if (tries > 2) {
            setError('Too many attempts. Please try again later.')
        } else {
            setSendingOTP(true);
            setTries(tries + 1)
            let x = 2 - tries;
            setError(`${x} Attempts remaining`)
            API.post('/auth/resend-otp-phone', {phone: props.location.state.phone, type:"REGISTER"}).then(res => {
                setSendingOTP(false);
                setSuccessMessage('OTP sent successfully.')
                setToken(res.data.data.otpToken);
            }).catch(error => {
                setSendingOTP(false);
                setError(error.response.data.message);
            })
        }
    }

    return (
        _token && <div className="bg-gray-100 py-2">
          <Header name='Enter OTP'/>
          <div className="px-4 max-w-6xl mx-auto pb-8">
                <div className="flex flex-col">
                    <label htmlFor="businessName" className="text-sm font-regular text-gray-800 mb-1">Enter OTP sent on your mobile number +91{props.location.state.phone}</label>
                    <input id="otp" onChange={e=>{setValue(e.target.value)}} maxLength="6" type="text" className="w-full mt-2 sm:w-80 text-sm sm:text-base placeholder-gray-500 pl-3 pr-3 rounded-md border border-gray-400 py-1 focus:outline-none focus:border-blue-400" />
                </div>
                <p className="my-2 text-sm text-red-600 font-semibold">{_error}</p>
                <div className="flex my-10 items-center">
                    <p className="text-xs">Didn't recieve OTP?</p>
                    <div onClick={resendOTP} className="ml-4 py-1 px-4 text-xs bg-green-400 rounded-full font-semibold cursor-pointer hover:bg-green-500">
                        {
                            sendingOTP ?
                            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                            :
                            <span>Resend OTP</span>
                        }
                    </div>
                </div>

                <p className="text-sm text-green-600 font-semibold mb-6">{successMessage}</p>

                <div onClick={handleSubmit} className="flex items-center justify-center mb-10 bg-indigo-500 w-full sm:w-64 text-center py-2 px-8 cursor-pointer hover:bg-indigo-600 rounded-md text-white font-semibold">
                {
                    submitted ?
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                    :
                    <span>Register My Account</span>
                }
                </div>
          </div>
        </div>
    )
}

export default VerifyPhone;
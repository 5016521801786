let Formdata = {};
let RawData = null;

const setDetails = (data) => {
    RawData = data;
    Formdata = data;
}

const setProducts = (val) => {
    const x = val.battery.filter(i => i.product != 'false');
    const y = val.inverter.filter(i => i.product != 'false')
    x.forEach(el => {
        const brands = el.brand.filter(i => i != false);
        el.brand = brands;
    });
    y.forEach(el => {
        const brands = el.brand.filter(i => i != false);
        el.brand = brands;
    });
    Formdata.battery = x;
    Formdata.inverter = y;
}

const setServices = (data) => {
    let services = [];
    for (const [key, value] of Object.entries(data.services)) {
        if(value) services.push(key);
    }
    Formdata.service = services;
}

const setTechnician = (data) => {
    const tech = data.technician.filter(i => i.name !== '')
    Formdata.technician = tech;
}

const setLocation = (lat, long, url, state, city, pincode, address, area) => {
    Formdata.lat = lat;
    Formdata.long = long;
    Formdata.locationUrl = url;
    Formdata.state = state;
    Formdata.city = city;
    Formdata.pincode = pincode;
    Formdata.address = address;
    Formdata.area = area;
}

const getFormData = () => {
    return Formdata;
}

const getRawData = () => {
    return RawData;
}

export default {
    setDetails,
    setTechnician,
    setProducts,
    setServices,
    setLocation,
    getFormData,
    getRawData,
}